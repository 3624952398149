import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { inject } from 'mobx-react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input } from 'reactstrap'
import FormValidator from '../Forms/FormValidator.js'
import '../Pages/login.css'

@inject('userStore')
class Login extends Component {
  state = {
    formLogin: {
      email: '',
      password: '',
    },
    isLoading: false,
    errorMessage: ''
  }

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  onSubmit = (e) => {
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      isLoading: true,
      [form.name]: {
        ...this.state[form.name],
        errors
      },
      errorMessage: ''
    })
    if (!hasError) {
      const postData = {
        email: this.state.formLogin.email,
        password: this.state.formLogin.password,
      }

      axios
        .post('/auth/login', postData)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('name', response.data.name)
            localStorage.setItem('surname', response.data.surname)
            localStorage.setItem('email', response.data.email)
            localStorage.setItem('function', response.data.function && response.data.function.name)

            axios.defaults.headers.common = {
              Authorization: `Bearer ${response.data.token}`
            }

            const decodedToken = jwt_decode(response.data.token)
            this.props.userStore.setAuthenticated(true)
            this.props.userStore.setUserLevel(decodedToken.user_level)
            this.props.userStore.setUserID(decodedToken.user_id)
            this.props.userStore.setUserEmail(decodedToken.email)
            this.props.userStore.setStartDate(decodedToken.start_date)
            this.props.userStore.setUserHasBirthday(decodedToken.userHasBirthday)

            this.props.history.push('/dashboard')
          } else {
            this.setState({
              errorMessage: response.data.message,
              isLoading: false
            })
          }
        })
        .catch((e) => {
          this.setState({ errorMessage: 'Server error', isLoading: false })
        })
    }

    e.preventDefault()
  }

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  render() {
    return (
      <div className='row h-100 main-container'>
        <div className='text-center col-lg-7 left-container' style={{ paddingLeft: 0 }}>
          <Link to='/login' className='text-muted'>
            <img className='block-center rounded img-fluid' src='img/logo.png' alt='Logo' />
          </Link>
        </div>
        <span className='divider' />
        <div className='col-lg-3 right-container'>
          <p className='text-center pb-3 pt-2 p'>KYÇU PËR TË VAZHDUAR.</p>

          {this.state.errorMessage !== '' && <p className='text-center text-danger'>{this.state.errorMessage}</p>}
          <form className='mb-3' name='formLogin' onSubmit={this.onSubmit}>
            <div className='form-group'>
              <div className='input-group with-focus'>
                <Input
                  type='email'
                  name='email'
                  className='border-right-0'
                  placeholder='Përdoruesi'
                  invalid={this.hasError('formLogin', 'email', 'required')}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formLogin.email}
                />
                <div className='input-group-append'>
                  <span className='input-group-text text-muted bg-transparent border-left-0'>
                    <em className='fa fa-envelope' />
                  </span>
                </div>
                {this.hasError('formLogin', 'email', 'required') && <span className='invalid-feedback'>Fusha është e nevojshme</span>}
              </div>
            </div>
            <div className='form-group'>
              <div className='input-group with-focus'>
                <Input
                  type='password'
                  id='id-password'
                  name='password'
                  className='border-right-0'
                  placeholder='Fjalëkalimi'
                  invalid={this.hasError('formLogin', 'password', 'required')}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formLogin.password}
                  autoComplete='off'
                />
                <div className='input-group-append'>
                  <span className='input-group-text text-muted bg-transparent border-left-0'>
                    <em className='fa fa-lock' />
                  </span>
                </div>
                <span className='invalid-feedback'>Fusha është e nevojshme</span>
              </div>
            </div>
            <div className='clearfix'>
              <div className='float-left'>
                <Link to='/recoverPassword' className='text-muted'>
                  Keni harruar passwordin?
                </Link>
              </div>
            </div>
            <button className='btn btn-block btn-info mt-3 login-btn' type='submit'>
              Kyçu
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default Login
