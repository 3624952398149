import { Box, Button, Card, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts'
import { RenderChips, enum_assignment_type, enum_task_type } from '../../utils'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Locale from 'date-fns/locale/en-GB'

const Task_Details = () => {
  const params = useParams()
  const search = useLocation().search

  const paramsSearch = new URLSearchParams(search)
  const setDates = paramsSearch.get('date')
  const id = params.id
  const [taskData, setTaskData] = useState(null)
  const [completed, setCompleted] = useState([])
  const [unCompleted, setUnCompleted] = useState([])
  const [date, setDate] = useState(setDates)

  useEffect(() => {
    axios
      .get(`taskReport/task/${id}`, { params: { date } })
      .then((res) => {
        if (res.data.success) {
          setTaskData(res.data.data.taskData)
          setCompleted(res.data.data.completed)
          setUnCompleted(res.data.data.uncompleted)
        } else {
          setTaskData()
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [date])

  const chartData = [
    {
      name: 'Assigned',
      Count: taskData?.assigned_users?.length,
      color: '#16B1FF'
    },
    {
      name: 'Completed',
      Count: completed?.length,
      color: '#56CA00'
    },
    {
      name: 'Un Completed',
      Count: unCompleted?.length,
      color: '#FF3E1D'
    }
  ]

  return (
    <Box sx={{ padding: '20px 10px' }}>
      <Box sx={{ maxWidth: 'fit-content', marginBottom: '20px', marginLeft: 'auto' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <DatePicker
            label='Date'
            inputFormat='dd/MM/yyyy'
            value={date}
            backgroundColor='white'
            minDate={taskData?.created_at}
            maxDate={new Date()}
            onChange={(e) => setDate(e)}
            renderInput={(params) => <TextField {...params} sx={{ width: '150px', backgroundColor: '#fff' }} size='small' />}
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>
      </Box>
      {taskData ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <Card sx={{ height: '100%', border: 0, boxShadow: 0 }}>
                <Box display='flex' justifyContent='space-between' alignItems='center' pt={2} px={2}>
                  <Typography variant='h6' fontWeight='medium' textTransform='capitalize'>
                    {taskData?.name}
                  </Typography>
                </Box>
                <Box p={2}>
                  <Box mb={2} lineHeight={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} xl={6} sx={{ borderRight: '1px solid #aaa' }}>
                        <Typography className='header' fontWeight='bold'>
                          Description:
                        </Typography>
                        <Tooltip title={taskData?.description} arrow>
                          <Typography className='header'>{taskData?.description.slice(0, 190)}...</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3} sx={{ borderRight: '1px solid #aaa' }}>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Due Time:
                          </Typography>
                          <Typography className='header'>
                            {taskData?.due_time?.hours}:{taskData?.due_time?.minutes}
                          </Typography>
                        </Box>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Due Date:
                          </Typography>
                          <Typography className='header'>
                            {taskData.due_date ? moment(taskData?.due_date).format('DD/MM/YYYY') : 'Repetitive'}
                          </Typography>
                        </Box>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Due Assignment:
                          </Typography>
                          <Typography className='header'>
                            {taskData?.type === enum_assignment_type['per_department'] ? 'Per Department' : 'Per User'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Type:
                          </Typography>
                          <Typography className='header'>
                            {taskData?.task_type === enum_task_type['single'] ? 'Single' : 'Repetitive'}
                          </Typography>
                        </Box>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Created By:
                          </Typography>
                          <Typography className='header'>
                            {taskData?.created_by?.first_name} {taskData?.created_by?.last_name}
                          </Typography>
                        </Box>
                        <Box display='flex'>
                          <Typography className='header' fontWeight='bold'>
                            Is Active:
                          </Typography>
                          <Typography className='header'>{taskData?.is_active ? 'True' : 'False'}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: '10px' }} />
                  </Box>
                  {taskData.repetition.length > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>Repetition:</Typography>
                      <Grid container columnSpacing={1} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', ml: 1 }}>
                        <RenderChips activeDay={taskData?.repetition} />
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Card sx={{ padding: '10px 20px', marginTop: '20px', height: '100%', border: 0, boxShadow: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4}>
                <Typography variant='h6' sx={{ marginBottom: '10px' }}>
                  Task Report Details
                </Typography>
                <BarChart width={430} height={250} data={chartData}>
                  <Bar dataKey='Count' label={{ fill: 'white' }}>
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                  </Bar>
                  <Tooltip />
                  <XAxis dataKey='name' />
                  <YAxis />
                </BarChart>
              </Grid>
              <Grid item xs={12} md={12} xl={8}>
                <Box sx={{ flex: 1, marginLeft: '20px' }}>
                  <Typography variant='h6' sx={{ marginBottom: '10px' }}>
                    Not Completed By
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        '> p': { flex: 1, textAlign: 'center' },
                        padding: '5px',
                        marginBottom: '10px',
                        borderRadius: '10px'
                      }}
                    >
                      <Typography fontWeight={'bold'}>Name</Typography>
                      <Typography fontWeight={'bold'}>Project</Typography>
                      <Typography fontWeight={'bold'}>Department</Typography>
                      <Typography fontWeight={'bold'}>View</Typography>
                    </Box>
                    <Box
                      sx={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        borderRadius: '10px'
                      }}
                    >
                      {unCompleted?.map((item) => (
                        <Box
                          sx={{
                            display: 'flex',
                            '> p': { flex: 1, textAlign: 'center' },
                            padding: '8px',
                            borderBottom: '1px solid',
                            ':last-of-type': { borderBottom: 'initial' }
                          }}
                        >
                          <Typography>
                            {item?.first_name} {item?.last_name}
                          </Typography>
                          <Typography>{item?.project?.name}</Typography>
                          <Typography>{item?.department[0]?.name}</Typography>
                          <Box sx={{ flex: 1, textAlign: 'center' }}>
                            <Link to={`/editUsers/${item?._id}`}>
                              <Button size='small' variant='contained'>
                                Profile
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ padding: '10px 20px', marginTop: '20px', height: '100%', border: 0, boxShadow: 0, overflow: 'auto' }}>
            <Typography variant='h6' sx={{ marginBottom: '10px' }}>
              Completed Details
            </Typography>
            <Box
              sx={{
                minWidth: '800px',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  '> p': { flex: 1, textAlign: 'center' },
                  padding: '5px',
                  marginBottom: '10px',
                  borderRadius: '10px'
                }}
              >
                <Typography fontWeight={'bold'}>Name</Typography>
                <Typography fontWeight={'bold'}>Project</Typography>
                <Typography fontWeight={'bold'}>Department</Typography>
                <Typography fontWeight={'bold'}>Location</Typography>
                <Typography fontWeight={'bold'}>Floor</Typography>
                <Typography fontWeight={'bold'}>Comment</Typography>
                <Typography fontWeight={'bold'}>Completed Time</Typography>
                <Typography fontWeight={'bold'}>View</Typography>
              </Box>
              <Box
                sx={{
                  maxHeight: '400px',
                  overflowY: 'auto'
                }}
              >
                {completed?.map((item) => (
                  <Box
                    sx={{
                      display: 'flex',
                      '> p': { flex: 1, textAlign: 'center' },
                      padding: '8px',
                      borderBottom: '1px solid',
                      ':last-of-type': { borderBottom: 'initial' },
                      backgroundColor: '#f5f7fa',
                      borderRadius: '10px'
                    }}
                  >
                    <Typography>
                      {item?.created_by?.first_name} {item?.created_by?.last_name}
                    </Typography>
                    <Typography>{item?.created_by?.project?.name}</Typography>
                    <Typography>{item?.created_by?.department[0]?.name}</Typography>
                    <Typography>{item?.location}</Typography>
                    <Typography>{item?.floor}</Typography>
                    <Typography>{item?.comment}</Typography>
                    <Typography>{moment(item?.created_date).format('HH:MM')}</Typography>
                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                      <Link to={`/editUsers/${item?.created_by?._id}`}>
                        <Button size='small' variant='contained'>
                          Profile
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Card>
        </>
      ) : (
        <Typography>Nuk ka te dhena per kete date, zgjidh nje date tjeter!</Typography>
      )}
    </Box>
  )
}

export default Task_Details
