import Users_Report_Table_Toolbar from '../components/Users_Report_Table_Toolbar/Users_Report_Table_Toolbar'

export const useTableOptions = (total, params, setParams) => {
  return {
    count: total,
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPage: 5,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setParams((prev) => ({ ...prev, page: e }))
    },
    customToolbar: () => <Users_Report_Table_Toolbar params={params} setParams={setParams} />
  }
}
