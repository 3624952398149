import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import { Button, Grid, Tooltip, Typography, Chip, Avatar, Box } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const enum_task_type = {
  single: 'single',
  repetitive: 'repetitive'
}

export const enum_assignment_type = {
  per_department: 'per_department',
  per_user: 'per_user'
}

export const week_days = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 }
]

export const tasks_header = [
  { label: 'Name', width: '100px' },
  { label: 'Description', width: '150px' },
  { label: 'Repetition', width: '100px' },
  { label: 'Due Time', width: '100px' },
  { label: 'Due Date', width: '100px' },
  { label: 'Action', width: '100px' }
]
export const floors = [
  { name: 'Floor 1', value: 1 },
  { name: 'Floor 2', value: 2 },
  { name: 'Floor 3', value: 3 },
  { name: 'Floor 4', value: 4 },
  { name: 'Floor 5', value: 5 },
  { name: 'Floor 6', value: 6 }
]

export const office_location = [
  { name: 'Home Office', value: 'Home Office' },
  { name: 'Prishtinë', value: 'Prishtinë' },
  { name: 'Prishtinë HQ', value: 'Prishtinë HQ' },
  { name: 'Vushtrri', value: 'Vushtrri' },
  { name: 'Ferizaj', value: 'Ferizaj' },
  { name: 'Prizren', value: 'Prizren' }
]

export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).replace(/_/g, ' ')

export const unCheckedIcon = <CheckBoxOutlineBlank fontSize='small' />
export const checkedIcon = <CheckBoxOutlined fontSize='small' />

export const manageTasksView = (item, task_user) => {
  const assignedDepartments = item.assigned_departments?.map((item) => item?.name).join(', ')
  const assignedUsers = item.assigned_users?.map((item) => `${item.first_name} ${item.last_name}`).join(', ')
  const repetition_days = item.repetition?.map((item) => week_days.find((i) => i.value === item)?.name).join(', ')
  return [
    item?.name,
    item?.description,
    item.task_type === enum_task_type.single ? 'Single' : 'Repetitive',
    <Tooltip key={item?._id} title={repetition_days}>
      <Typography>{item.repetition.map((item) => `${item} `)}</Typography>
    </Tooltip>,
    <Tooltip key={item?._id} title={item.assignment_type === enum_assignment_type.per_department ? assignedDepartments : assignedUsers}>
      <Typography>{item.assignment_type === enum_assignment_type.per_department ? 'Per Department' : 'Per User'}</Typography>
    </Tooltip>,
    `${item?.due_time?.hours}:${item?.due_time?.minutes}`,
    item.due_date ? moment(item.due_date).format('DD/MM/YYYY') : '',
    !task_user && (
      <Link to={`/manage-tasks/${item?._id}`}>
        <Button size='small' variant='contained'>
          View
        </Button>
      </Link>
    )
  ]
}

export const RenderItems = ({ taskDetails }) =>
  taskDetails.map(({ title, value }, key) => (
    <Box key={key} display='flex'>
      <Typography variant='button' fontWeight='bold' textTransform='capitalize'>
        {title}: &nbsp;
      </Typography>
      <Typography variant='buton' fontWeight='regular' color='text'>
        &nbsp;{value}
      </Typography>
    </Box>
  ))

export const RenderProfiles = ({ assignedUsers }) =>
  assignedUsers.map(({ _id, first_name, last_name }) => (
    <Box key={_id} component='li' display='flex' alignItems='center' py={1} mb={1}>
      <Box mr={2}>
        <Avatar src={''} alt='something here' shadow='' />
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typography variant='buton' fontWeight='medium'>
          {first_name} {last_name}
        </Typography>
        <Typography variant='caption' color='text'>
          <Link to={`/editUsers/${_id}`}>Profile</Link>
        </Typography>
      </Box>
    </Box>
  ))
export const RenderDepartemnts = ({ assignedDepartments }) =>
  assignedDepartments.map(({ _id, name }) => (
    <Box key={_id} component='li' display='flex' alignItems='center' py={1} mb={1}>
      <Box mr={2}>
        <Avatar src={''} alt='something here' shadow=''>
          {name.charAt(0)}
        </Avatar>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typography variant='button' fontWeight='medium'>
          {name}
        </Typography>
      </Box>
    </Box>
  ))

export const RenderChips = ({ activeDay }) =>
  week_days.map((day, index) => {
    const isInArray = activeDay?.includes(day.value)

    return (
      <Grid item>
        <Chip
          key={day.value}
          label={`${index + 1}: ${day.name}`}
          size='small'
          variant={isInArray ? '' : 'outlined'}
          color={isInArray ? 'success' : 'default'}
        />
      </Grid>
    )
  })
