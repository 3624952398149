import { Box, Button } from '@mui/material'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import New_Tasks_User_Profile_Modal from '../New_Tasks_User_Profile_Modal/New_Tasks_User_Profile_Modal'
import Tasks_Admins_Table_Toolbar from '../Tasks_Profiles_Table_Toolbar/Tasks_Profiles_Table_Toolbar'
import Remove_Task_User_Profile_Modal from '../Remove_Task_User_Profile_Modal/Remove_Task_User_Profile_Modal'

const Tasks_Users = () => {
  const [addNewModal, setAddNewModal] = useState(false)
  const [params, setParams] = useState({ limit: 5, page: 0, name: null })
  const [total, setTotal] = useState(null)
  const [taskUsers, setTaskUsers] = useState([])
  const [userToRemove, setUserToRemove] = useState(null)

  const getTaskUsers = () => {
    axios
      .get(`taskUsers/users`, { params: { ...params, filter: true } })
      .then((res) => {
        if (res.data.success) {
          const tableData = res.data.data?.map((item) => {
            return [
              item?.first_name,
              item?.last_name,
              item?.department?.map((item) => item?.name).join(', '),
              item?.office_location,
              <Link to={`/editUsers/${item?._id}`}>
                <Button variant='contained' size='small'>
                  Profile
                </Button>
              </Link>,
              <Button
                onClick={() => setUserToRemove({ name: `${item?.first_name} ${item?.last_name}`, id: item?._id })}
                variant='contained'
                size='small'
                color='error'
              >
                Remove
              </Button>
            ]
          })
          setTaskUsers(tableData)
          setTotal(res.data.total)
        } else {
          setTaskUsers([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }
  useEffect(() => {
    getTaskUsers()
  }, [params])

  const handleAddingNewTaskProfiles = () => {
    setAddNewModal(false)
    getTaskUsers()
  }

  const options = {
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    count: total,
    rowsPerPage: 5,
    elevation: 0,
    responsive: 'scroll',
    maxHeight: 'none',
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setParams((prev) => ({ ...prev, page: e }))
    },
    customToolbar: () => <Tasks_Admins_Table_Toolbar params={params} setParams={setParams} openModal={() => setAddNewModal(true)} users />
  }

  const columns = [
    { name: 'first_name', label: 'First Name', options: { sort: false } },
    { name: 'last_name', label: 'Last Name', options: { sort: false } },
    { name: 'department', label: 'Department', options: { sort: false } },
    { name: 'location', label: 'Location', options: { sort: false } },
    { name: 'user_profile', label: 'User Profile', options: { sort: false } },
    { name: 'action', label: 'Action', options: { sort: false } }
  ]

  return (
    <Box>
      <MUIDataTable columns={columns} options={options} data={taskUsers} />
      {addNewModal && <New_Tasks_User_Profile_Modal handleAddingNewTaskProfiles={handleAddingNewTaskProfiles} users={true} />}
      {userToRemove && (
        <Remove_Task_User_Profile_Modal
          type='User'
          closeModal={() => {
            setUserToRemove(null)
            getTaskUsers()
          }}
          {...userToRemove}
        />
      )}
    </Box>
  )
}

export default Tasks_Users
