import { ExpandMore } from '@material-ui/icons'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { useState } from 'react'
import Tasks_Admins from './..//Tasks_Admins/Tasks_Admins'
import Tasks_Users from './../Tasks_Users/Tasks_Users'
import Manage_Task_Table from './components/Manage_Task_Table/Manage_Task_Table'
import jwt_decode from 'jwt-decode'

const accordions = [
  {
    title: 'Task Admins',
    component: <Tasks_Admins />
  },
  {
    title: 'Task Users',
    component: <Tasks_Users />
  }
]

const Manage_Tasks_Tab = () => {
  const token = localStorage.getItem('token')
  const decodedToken = token ? jwt_decode(token) : null
  const [expandedAccordion, setExpandedAccordion] = useState(null)

  return (
    <>
      {decodedToken?.isTaskAdmin && (
        <Box sx={{ marginY: '20px' }}>
          {accordions?.map(({ title, component }) => (
            <Accordion
              expanded={expandedAccordion === title}
              onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? title : null)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>{expandedAccordion === title && component}</AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
      <Manage_Task_Table />
    </>
  )
}

export default Manage_Tasks_Tab
