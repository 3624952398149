import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  OutlinedInput,
  ListItemText,
  TextField
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { capitalizeFirstLetter, floors, office_location } from '../../utils'
import { toast } from 'react-toastify'

const Complete_Task_Modal = ({ task_id, onCloseModal }) => {
  const [taskDetails, setTaskDetails] = useState([])
  const [newReportData, setNewReportData] = useState([
    {
      floor: '',
      location: '',
      comment: ''
    }
  ])

  useEffect(() => {
    axios
      .get(`task/${task_id}`)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data
          setTaskDetails([
            {
              title: 'Name',
              value: data?.name
            },
            {
              title: 'Description',
              value: data?.description
            },
            {
              title: 'Due Time',
              value: `${data?.due_time?.hours}:${data?.due_time?.minutes}`
            }
          ])
        } else {
          setTaskDetails([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [task_id])

  const onSubmit = async () => {
    if (!newReportData?.floor || !newReportData?.location) {
      return toast.error('Floor and Location are required')
    }

    axios
      .post(`taskReport/${task_id}`, newReportData)
      .then((res) => {
        if (res.data.success) {
          toast.success('Task successfully completed!')
          onCloseModal()
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }

  return (
    <Dialog open fullWidth sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>
        <Typography variant='h5'>Complete Task</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant='h6'>Details</Typography>
          {taskDetails.map(({ title, value }) => (
            <Box key={title} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{title}: </Typography>
              <Typography sx={{ marginLeft: '10px' }}>{value}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <Box>
            <FormControl sx={{ width: '180px', textAlign: 'left' }}>
              <InputLabel size='small' id='task_report_floor_label'>
                Floor
              </InputLabel>
              <Select
                size='small'
                labelId='task_report_floor_label'
                value={newReportData?.floor ?? ''}
                onChange={(e) => setNewReportData((prev) => ({ ...prev, floor: e.target.value }))}
                input={<OutlinedInput label='Floor' size='small' />}
              >
                {floors.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    <ListItemText primary={capitalizeFirstLetter(name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '180px', textAlign: 'left', marginLeft: '25px' }}>
              <InputLabel size='small' id='task_report_location_label'>
                Location
              </InputLabel>
              <Select
                size='small'
                labelId='task_report_location_label'
                value={newReportData?.location ?? ''}
                onChange={(e) => setNewReportData((prev) => ({ ...prev, location: e.target.value }))}
                input={<OutlinedInput label='Location' size='small' />}
              >
                {office_location.map(({ value, name }) => (
                  <MenuItem key={value} value={value}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TextField
            value={newReportData?.comment}
            onChange={(e) => setNewReportData((prev) => ({ ...prev, comment: e.target.value }))}
            label='Comment'
            placeholder='Comment'
            variant='outlined'
            size='small'
            fullWidth
            sx={{ marginTop: '15px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} size='small' variant='contained' color='error'>
          Cancel
        </Button>
        <Button onClick={onSubmit} size='small' variant='contained'>
          Complete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Complete_Task_Modal
