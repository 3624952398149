import { Button, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { enum_assignment_type, enum_task_type, week_days } from '../../../../../utils'

export const useTableData = (item, task_user) => {
    const assignedDepartments = item.assigned_departments?.map((item) => item?.name).join(', ')
    const assignedUsers = item.assigned_users?.map((item) => `${item.first_name} ${item.last_name}`).join(', ')
    const repetition_days = item.repetition?.map((item) => week_days.find((i) => i.value === item)?.name).join(', ')
    return [
      item?.name,
      item?.description,
      item.task_type === enum_task_type.single ? 'Single' : 'Repetitive',
      <Tooltip key={item?._id} title={repetition_days}>
        <Typography>{item.repetition.map((item) => `${item} `)}</Typography>
      </Tooltip>,
      <Tooltip key={item?._id} title={item.assignment_type === enum_assignment_type.per_department ? assignedDepartments : assignedUsers}>
        <Typography>{item.assignment_type === enum_assignment_type.per_department ? 'Per Department' : 'Per User'}</Typography>
      </Tooltip>,
      `${item?.due_time?.hours}:${item?.due_time?.minutes}`,
      item.due_date ? moment(item.due_date).format('DD/MM/YYYY') : '',
      !task_user && (
        <Link to={`/manage-tasks/${item?._id}`}>
          <Button size='small' variant='contained'>
            View
          </Button>
        </Link>
      )
    ]
  }
  