import { Box, Button } from '@mui/material'
import { useState } from 'react'
import My_Today_Tasks_Tab from '../components/My_Today_Tasks_Tab/My_Today_Tasks_Tab'
import UserDetails from './Details/User'

const My_Tasks = () => {
  const [tabIndex, setTabIndex] = useState(1)

  return (
    <Box sx={{ padding: '20px', height: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Button
          variant={tabIndex === 1 ? 'outlined' : 'contained'}
          disabled={tabIndex === 1}
          onClick={() => setTabIndex(1)}
          fullWidth
          sx={{ marginRight: '10px' }}
        >
          Today Tasks
        </Button>
        <Button
          variant={tabIndex === 2 ? 'outlined' : 'contained'}
          disabled={tabIndex === 2}
          onClick={() => setTabIndex(2)}
          fullWidth
          sx={{ marginLeft: '10px' }}
        >
          Tasks Reports
        </Button>
      </Box>
      {tabIndex === 1 && <My_Today_Tasks_Tab />}
      {tabIndex === 2 && <UserDetails />}
    </Box>
  )
}

export default My_Tasks
