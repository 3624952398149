import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const useTableData = (item, date) => {
  const totalTasks = item?.total
  const tasksCompleted = item?.completed
  const tasksNotCompleted = item?.uncompleted
  const percentage = (tasksCompleted?.length / totalTasks.length) * 100
  const percentageCompleted = percentage.toFixed(percentage % 1 === 0 ? 0 : 2) + '%'

  return [
    `${item?.userData?.first_name} ${item?.userData?.last_name}`,
    item?.userData?.project?.name,
    item?.userData?.department[0]?.name,
    <Box sx={{ display: 'flex', '> .centerAligned': { flex: 1, textAlign: 'center' } }}>
      <Tooltip
        title={totalTasks?.map((task) => (
          <div key={task._id}>{task.name}</div>
        ))}
      >
        <Typography className='centerAligned'>{totalTasks?.length}</Typography>
      </Tooltip>
      <Typography>/ </Typography>
      <Tooltip
        title={
          tasksCompleted?.length > 0 ? tasksCompleted?.map((task) => <div key={task._id}>{task.name}</div>) : 'No one task is completed'
        }
      >
        <Typography className='centerAligned'>{tasksCompleted?.length}</Typography>
      </Tooltip>
      <Typography>/</Typography>
      <Tooltip
        title={
          tasksNotCompleted?.length > 0
            ? tasksNotCompleted?.map((task) => <div key={task._id}>{task.name}</div>)
            : 'All tasks are completed'
        }
      >
        <Typography className='centerAligned'>{tasksNotCompleted.length}</Typography>
      </Tooltip>
    </Box>,
    <Box
      sx={{
        background: `linear-gradient(90deg, rgba(0,255,29,1) 0%, rgba(255,255,255,1) ${percentageCompleted})`,
        paddingX: '5px',
        width: '90%'
      }}
    >
      {percentageCompleted}
    </Box>,
    <Link to={`/tasks-reports/user/${item?.userData?._id}?date=${date}`}>
      <Button size='small' variant='contained'>
        View
      </Button>
    </Link>
  ]
}
