import { Box, Button, TextField } from '@mui/material'

const Tasks_Profiles_Table_Toolbar = ({ setParams, openModal, users }) => {
  return (
    <Box sx={{ fieldset: { border: '1px solid' } }}>
      <TextField
        label={`Search Tasks ${users ? 'Users' : 'Admins'}`}
        size='small'
        sx={{ marginRight: '10px' }}
        onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
      />
      <Button variant='contained' size='small' onClick={openModal}>
        Add new
      </Button>
    </Box>
  )
}

export default Tasks_Profiles_Table_Toolbar
