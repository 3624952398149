import React from 'react'
import Manage_Tasks_Table_Toolbar from '../components/Manage_Tasks_Table_Toolbar/Manage_Tasks_Table_Toolbar'

const useTableOptions = (total, is_active, params, setParams) => {
  return {
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    count: total,
    rowsPerPage: 5,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setParams((prev) => ({ ...prev, page: e }))
    },
    customToolbar: () => <Manage_Tasks_Table_Toolbar isActive={is_active} params={params} setParams={setParams} />
  }
}

export default useTableOptions
