import { Grid, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Complete_Task_Modal from './../Complete_Task_Modal/Complete_Task_Modal'
import Task_Report_Modal from './../Task_Report_Modal/Task_Report_Modal'
import My_Today_Tasks_Timeline from './My_Today_Tasks_Timeline'

const My_Today_Tasks_Tab = () => {
  const [allTasks, setAllTasks] = useState([])

  const [isCompletedTask, setIsCompletedTask] = useState(false)
  const [taskId, setTaskId] = useState(null)

  useEffect(() => {
    axios
      .get(`task/my_tasks_for_today`)
      .then((res) => {
        if (res.data.success) {
          if (res?.data?.data) {
            setAllTasks(res.data.data)
          }
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [taskId])
  return (
    <Grid>
      <Typography variant='h5'>My Tasks</Typography>

      {allTasks && <My_Today_Tasks_Timeline tasks={allTasks} setTaskId={setTaskId} setIsCompletedTask={setIsCompletedTask} />}

      {isCompletedTask && (
        <Task_Report_Modal
          task_id={taskId}
          onCloseModal={() => {
            setTaskId(null)
            setIsCompletedTask(false)
          }}
        />
      )}
      {taskId && !isCompletedTask && <Complete_Task_Modal task_id={taskId} onCloseModal={() => setTaskId(null)} />}
    </Grid>
  )
}

export default My_Today_Tasks_Tab
