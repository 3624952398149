import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'

const Remove_Task_User_Profile_Modal = ({ type, closeModal, name, id }) => {
  const handleRemoveUserProfile = () => {
    axios
      .delete(`taskUsers/${type.toLowerCase()}/${id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success('User was removed successfully!')
          closeModal()
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }
  return (
    <Dialog open maxWidth='md'>
      <DialogTitle>
        Are you certain you wish to remove {name} from Task {type} ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => closeModal()} size='small' variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => handleRemoveUserProfile()} size='small' variant='contained' color='error'>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Remove_Task_User_Profile_Modal
