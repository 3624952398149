import { Box, Typography } from '@mui/material'
import Tasks_Report_Table from './components/Tasks_Report_Table/Tasks_Report_Table'
import Users_Report_Table from './components/Users_Report_Table/Users_Report_Table'

const Tasks_Report_Tab = () => {
  return (
    <Box sx={{ my: 5 }}>
      <Tasks_Report_Table />
      <Users_Report_Table />
    </Box>
  )
}

export default Tasks_Report_Tab
