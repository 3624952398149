import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { columns } from './../../utils/columns'
import { useTableData } from './../../utils/useTableData'
import useTableOptions from './../../utils/useTableOptions'

const Table = ({ is_active = false, addNewModal, task_type = null }) => {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(null)
  const [params, setParams] = useState({
    limit: 5,
    page: 0,
    name: null,
    due_time: null,
    due_date: null,
    task_type: task_type,
    assignment_type: null,
    is_active: is_active
  })

  const options = useTableOptions(total, is_active, params, setParams)

  const fetchcTasks = async () => {
    try {
      const res = await axios.get('task', { params })

      if (res.data.success) {
        setTotal(res.data.total)
        const tableData = res?.data?.data?.map((item) => useTableData(item))
        setTasks(tableData)
      } else {
        setTasks([])
        toast.error(res.data.message)
      }
    } catch (err) {
      toast.error('Something went wrong!')
      console.error(err)
    }
  }

  useEffect(async () => {
    await fetchcTasks()
  }, [addNewModal, params])

  return (
    <div>
      <MUIDataTable columns={columns} options={options} data={tasks} />
    </div>
  )
}

export default Table
