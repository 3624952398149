import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import ClearIcon from '@material-ui/icons/Clear'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Box, Card, CardActions, CardContent, Collapse, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import ShowDetails from './ShowDetails'

const CardDetails = ({ color, title, tasks, type }) => {
  const [collapse, setCollapse] = useState(false)

  const handleClick = () => {
    setCollapse(!collapse)
  }

  const taskNames = (tasks || []).map((taskInfo) => taskInfo.name || taskInfo.task_id.name).join(', ')

  const truncatedTaskNames = taskNames.length > 30 ? taskNames.slice(0, 30) + '...' : taskNames

  return (
    <Card sx={{ border: 0, boxShadow: 0, borderColor: color, borderStyle: color }}>
      <CardContent sx={{ padding: (theme) => `${theme.spacing(3.25, 5, 2.5)} !important` }}>
        <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: color }}>
          {title}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h5' sx={{ color: color }}>
                {tasks.length}
              </Typography>
              {type === 'uncompleted' ? (
                <ClearIcon fontSize='medium' sx={{ marginRight: 1.25 }} />
              ) : (
                <AssignmentTurnedInIcon fontSize='medium' sx={{ marginRight: 1.25 }} />
              )}
            </Box>
          </Box>
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
          <Tooltip title={taskNames?.length > 10 ? taskNames : ''} arrow>
            <Typography variant='body2' sx={{ marginBottom: 0 }}>
              {taskNames ? `Task Names: ${truncatedTaskNames}` : 'No tasks'}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
      <CardActions className='card-action-dense'>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            padding: (theme) => `${theme.spacing(0, 3, 1.5)} !important`
          }}
        >
          <IconButton size='small' onClick={handleClick}>
            {collapse ? <KeyboardArrowUpIcon sx={{ fontSize: '1.875rem' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: '1.875rem' }} />}
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={collapse}>
        <Divider sx={{ margin: 0, borderColor: `${color} !important` }} />
        <CardContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          {tasks?.map((task, i) => (
            <ShowDetails
              task={{ ...task, task_id: task?.task_id ? task?.task_id : task }}
              hide={tasks.length === i + 1}
              color={color}
              key={i}
              type={type}
            />
          ))}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default CardDetails
