import { Box, Grid, Card, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Bar, BarChart, DefaultTooltipContent, Legend, Rectangle, Tooltip, XAxis, YAxis } from 'recharts'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Locale from 'date-fns/locale/en-GB'
import CardDetails from './Card/Card'
import PieChartData from './Chart/PieChart'
import jwtDecode from 'jwt-decode'
import ContentWrapper from '../../../../components/Layout/ContentWrapper'

const User_Reports_Details = () => {
  const { id } = useParams()
  const search = useLocation().search
  const paramsSearch = new URLSearchParams(search)
  const setDates = paramsSearch.get('date')
  const [userData, setUserData] = useState(null)
  const [total, setTotal] = useState([])
  const [completed, setCompleted] = useState([])
  const [unCompleted, setUnCompleted] = useState([])
  const [date, setDate] = useState(setDates || new Date())
  const [tasksData, setTasksData] = useState([])

  const token = localStorage.getItem('token')
  const decodedToken = jwtDecode(token)
  useEffect(() => {
    axios
      .get(`taskReport/user/${id ? id : decodedToken?.user_id}`, { params: { date } })
      .then((res) => {
        if (res.data.success) {
          setUserData(res.data.data.userData)
          setTasksData([
            { color: '#16B1FF', title: 'Assigned Tasks', data: res.data.data.total, type: 'assigned' },
            { color: '#56CA00', title: 'Completed Tasks', data: res.data.data.completed, type: 'completed' },
            { color: '#FF3E1D', title: 'UnCompleted Tasks', data: res.data.data.uncompleted, type: 'uncompleted' }
          ])
          setTotal(res.data.data.total)
          setCompleted(res.data.data.completed)
          setUnCompleted(res.data.data.uncompleted.length)
        } else {
          setUserData([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [date])

  function convertTimestampToDecimal(timestamp) {
    const dateObject = new Date(timestamp)
    const hours = dateObject.getHours()
    const minutes = dateObject.getUTCMinutes()
    const decimalRepresentation = hours + minutes / 60

    return decimalRepresentation
  }

  function convertDecimalToTime(decimalRepresentation) {
    const test = Math.ceil((decimalRepresentation - Math.floor(decimalRepresentation)) * 60).toString()
    return `${Math.floor(decimalRepresentation)}:${test.padStart(2, '0')}`
  }

  const newDataChart = completed.map((item) => ({
    name: item.task_id.name,
    'Afati i perfundimit': convertTimestampToDecimal(item.task_id.due_time.date_format),
    'Koha e kompletimit': convertTimestampToDecimal(item.created_date)
  }))

  const formatTime = (date) => {
    return `${date}:00`
  }

  const yTicks = Array.from({ length: 15 }, (_, index) => index + 9) // Create an array from 9 to 23

  const CustomTooltip = (props) => {
    if (props.payload[0] != null) {
      const newPayload = props.payload.map((item) => ({ ...item, value: convertDecimalToTime(item.value) }))
      return <DefaultTooltipContent {...props} payload={newPayload} />
    }
    return <DefaultTooltipContent {...props} />
  }
  return (
    <ContentWrapper>
      <Grid container spacing={2}>
        <Grid item md={12} lg={12} sx={{ marginLeft: '0', padding: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
              <DatePicker
                label='Date'
                inputFormat='dd/MM/yyyy'
                value={date}
                backgroundColor='white'
                maxDate={new Date()}
                onChange={(e) => setDate(e)}
                renderInput={(params) => <TextField {...params} sx={{ width: '150px', backgroundColor: '#fff' }} size='small' />}
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        {userData ? (
          <>
            <Grid item container spacing={2} xs={12} sx={{ marginLeft: '0' }}>
              {id && (
                <Card sx={{ width: '100%', boxShadow: 0, padding: '20px' }}>
                  <Grid container>
                    <Grid item sm={3}>
                      <Typography variant='body2' fontWeight='bold'>
                        Emri:
                      </Typography>
                      <Typography variant='body1'>
                        {userData.first_name} {userData.last_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant='body2' fontWeight='bold'>
                        Projekti:
                      </Typography>
                      <Typography variant='body1'>{userData?.project?.name}</Typography>
                    </Grid>{' '}
                    <Grid item sm={3}>
                      <Typography variant='body2' fontWeight='bold'>
                        Departamenti:
                      </Typography>
                      <Typography variant='body1'>{userData.department[0]?.name}</Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant='body2' fontWeight='bold'>
                        Lokacioni i punës:
                      </Typography>
                      <Typography variant='body1'>{userData?.office_location}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Grid>
            <Grid item container spacing={2} xs={12}>
              {tasksData.map(({ title, color, type, data }, i) => (
                <Grid item sm={12} md={12} lg={6} xl={4}>
                  <CardDetails color={color} title={title} tasks={data} type={type} userData={userData} total={total} />
                </Grid>
              ))}
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item sm={12} lg={8}>
                <Card sx={{ border: 0, boxShadow: 0, padding: 2 }}>
                  <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Taskat e kompletuara
                  </Typography>
                  <BarChart width={800} height={400} data={newDataChart}>
                    <XAxis dataKey='name' />
                    <YAxis type='number' domain={[9, 23]} tickFormatter={formatTime} ticks={yTicks} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey='Koha e kompletimit' fill='#56CA00' activeBar={<Rectangle stroke='black' />} />
                    <Bar dataKey='Afati i perfundimit' fill='#16B1FF' activeBar={<Rectangle stroke='black' />} />
                  </BarChart>
                </Card>
              </Grid>
              <Grid item sm={12} lg={4}>
                <Card sx={{ border: 0, boxShadow: 0, padding: 2 }}>
                  <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Total Tasks
                  </Typography>
                  <PieChartData completed={completed.length} uncompleted={unCompleted} />
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box>
            <Typography>Nuk ka te dhena per kete date, zgjidh nje date tjeter!</Typography>
          </Box>
        )}
      </Grid>
    </ContentWrapper>
  )
}

export default User_Reports_Details
