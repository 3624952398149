import { Box, Button } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import Manage_Tasks_Tab from '../components/Manage_Tasks_Tab/Manage_Tasks_Tab'
import Tasks_Report_Tab from './../components/Tasks_Report_Tab/Tasks_Report_Tab'
const Manage_Tasks = () => {
  const history = useHistory()
  const location = useLocation()
  const search = useLocation().search
  const paramsSearch = new URLSearchParams(search)
  const currentIndex = paramsSearch.get('index') || 1

  const setTabIndexOnQuery = (index) => {
    const params = new URLSearchParams({ index: index })
    history.replace({ pathname: location.pathname, search: params.toString() })
  }
  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant={currentIndex == 1 ? 'outlined' : 'contained'}
          disabled={currentIndex == 1}
          onClick={() => setTabIndexOnQuery(1)}
          fullWidth
          sx={{ marginRight: '10px' }}
        >
          Task Reports
        </Button>
        <Button
          variant={currentIndex == 2 ? 'outlined' : 'contained'}
          disabled={currentIndex == 2}
          onClick={() => setTabIndexOnQuery(2)}
          fullWidth
          sx={{ marginLeft: '10px' }}
        >
          Manage Tasks
        </Button>
      </Box>
      {currentIndex == 1 && <Tasks_Report_Tab />}
      {currentIndex == 2 && <Manage_Tasks_Tab />}
    </Box>
  )
}

export default Manage_Tasks
