export const columns = [
  { name: 'name', label: 'Name', options: { sort: false } },
  { name: 'description', label: 'Description', options: { sort: false } },
  { name: 'task_type', label: 'Task Type', options: { sort: false } },
  { name: 'repetition', label: 'Repetition', options: { sort: false } },
  { name: 'assignment_type', label: 'Assignment Type', options: { sort: false } },
  { name: 'due_time', label: 'Due Time', options: { sort: false } },
  { name: 'due_date', label: 'Due Date', options: { sort: false } },
  { name: 'more', label: 'More', options: { sort: false } }
]
