import { ArrowBack } from '@material-ui/icons'
import { Box, Button, Card, Dialog, DialogActions, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RenderChips, RenderDepartemnts, RenderItems, RenderProfiles, capitalizeFirstLetter } from '../utils'

const Task_Details = () => {
  const { id } = useParams()
  const [taskDetails, setTaskDetails] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [assignedDepartments, setAssignedDepartments] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [isActive, setIsActive] = useState()

  useEffect(() => {
    axios
      .get(`task/${id}`)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data
          setIsActive(data.is_active)
          setTaskDetails([
            {
              title: 'Name',
              value: data?.name
            },
            {
              title: 'Description',
              value: data?.description
            },
            {
              title: 'Type',
              value: capitalizeFirstLetter(data?.task_type ?? '')
            },
            {
              title: 'Assignment Type',
              value: capitalizeFirstLetter(data?.assignment_type ?? '')
            },
            {
              title: 'Repetition',
              value: data.repetition.map((item) => item)
            },
            {
              title: 'Due Time',
              value: `${data?.due_time?.hours}:${data?.due_time?.minutes}`
            },

            {
              title: 'Due Date',
              value: data.due_date ? moment(data.due_date).format('DD/MM/YYYY') : ''
            }
          ])
          setAssignedUsers(data.assigned_users)
          setAssignedDepartments(data.assigned_departments)
        } else {
          setTaskDetails([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [id])

  const handleDeactivateTask = async (is_active) => {
    setOpenModal(false)
    axios
      .patch(`task/deactivate/${id}`, { is_active: is_active })
      .then((res) => {
        if (res.data.success) {
          toast.success('Task activated status changed successfully!')
          history.back()
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }

  const history = useHistory()
  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button size='small' variant='outlined' sx={{ marginBottom: '20px' }} startIcon={<ArrowBack />} onClick={() => history.goBack()}>
          Back
        </Button>
        <Box>
          <Dialog open={openModal}>
            <DialogTitle>Are you sure you want to deactivate this task ?</DialogTitle>
            <DialogActions>
              <Button variant='contained' color='success' onClick={() => setOpenModal(false)}>
                No
              </Button>
              <Button variant='contained' color='error' onClick={() => handleDeactivateTask(!isActive)}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {isActive ? (
            <>
              <Typography>Active</Typography>
              <Button variant='contained' onClick={() => setOpenModal(true)}>
                Deactivate
              </Button>
            </>
          ) : (
            <>
              <Typography>InActive</Typography>
              <Button variant='contained' onClick={() => setOpenModal(true)}>
                Activate
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} xl={12}>
            <Card sx={{ height: '100%', border: 0, boxShadow: 0, color: '#aaa' }}>
              <Box display='flex' justifyContent='space-between' alignItems='center' pt={2} px={2}>
                <Typography variant='h6' fontWeight='medium' textTransform='capitalize'>
                  {taskDetails[0]?.value}
                </Typography>
              </Box>
              <Box p={2}>
                <Box mb={2} lineHeight={1}>
                  <Typography color='dark' fontWeight='light'>
                    {taskDetails[1]?.value}
                  </Typography>
                </Box>
                <Box opacity={0.8}>
                  <Divider />
                </Box>
                <Box mt={5}>
                  <RenderItems taskDetails={taskDetails.slice(2)} />
                  <Box display='flex' py={1} pr={2}></Box>
                </Box>
                {taskDetails[4]?.value.length > 0 && (
                  <Box display='flex' sx={{ gap: '20px' }} py={1} pr={2}>
                    <Typography>Repetition Description:</Typography>
                    <RenderChips activeDay={taskDetails[4]?.value} />
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={8} sx={{ display: 'flex' }}>
            <Card sx={{ height: '100%', width: '100%', border: 0, boxShadow: 0, color: '#aaa' }}>
              <Box pt={2} px={2}>
                <Typography variant='h6' fontWeight='medium' textTransform='capitalize'>
                  Assigned User
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  component='ul'
                  p={2}
                  m={0}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px 50px',
                    '> li': {
                      minWidth: '200px'
                    }
                  }}
                >
                  <RenderProfiles assignedUsers={assignedUsers} />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex' }}>
            <Card sx={{ height: '100%', width: '100%', border: 0, boxShadow: 0, color: '#aaa' }}>
              <Box pt={2} px={2}>
                <Typography variant='h6' fontWeight='medium' textTransform='capitalize'>
                  Assigned Departemnt
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  component='ul'
                  p={2}
                  m={0}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px 50px',
                    '> li': {
                      minWidth: '200px'
                    }
                  }}
                >
                  <RenderDepartemnts assignedDepartments={assignedDepartments} />
                </Box>
              </Box>
            </Card>
            <Divider orientation='vertical' sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Task_Details
