import { Box, Button, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import Locale from 'date-fns/locale/en-GB'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'

const Users_Report_Table_Toolbar = ({ params, setParams }) => {
  const downloadCSV = () => {
    axios
      .get(`taskReport/users/download`, {
        responseType: 'blob',
        params
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `Users_Reports.csv`)
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }
  return (
    <Box sx={{ fieldset: { border: '1px solid' }, paddingX: '10px' }}>
      <TextField
        label='Search by name'
        size='small'
        sx={{ marginRight: '10px', width: '150px' }}
        onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
        <DatePicker
          label='Date'
          inputFormat='dd/MM/yyyy'
          value={params?.date}
          onChange={(e) => setParams((prev) => ({ ...prev, page: 0, date: e }))}
          renderInput={(params) => <TextField {...params} sx={{ marginX: '10px', width: '150px' }} size='small' />}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
        />
      </LocalizationProvider>

      <Button size='small' variant='contained' onClick={() => downloadCSV()}>
        Download CSV
      </Button>
    </Box>
  )
}

export default Users_Report_Table_Toolbar
