import { Chip, Grid, StepLabel, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import Stepper from '@mui/material/Stepper'
import moment from 'moment'
import { RenderChips } from '../../utils'

const My_Today_Tasks_Timeline = ({ tasks, setIsCompletedTask, setTaskId }) => {
  const firstDividedBy3 = Math.floor(tasks.length / 3)
  const secondDividedBy2 = (tasks.length - firstDividedBy3) / 2
  const mathCeilSecondDividedBy2 = Math.ceil(secondDividedBy2)
  const mathFloor = Math.floor(secondDividedBy2)

  const firstArray = tasks.slice(0, mathCeilSecondDividedBy2)
  const secondArray = tasks.slice(mathCeilSecondDividedBy2, mathCeilSecondDividedBy2 + mathFloor)
  const thirdArray = tasks.slice(mathCeilSecondDividedBy2 + mathFloor)

  return (
    <Grid container>
      {[firstArray, secondArray, thirdArray].map((tasks, groupIndex) => (
        <Grid item sm={12} md={12} lg={6} xl={4}>
          <Stepper key={groupIndex} orientation='vertical'>
            {tasks.map((step, index) => (
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  '> div': {
                    '&:first-child': {
                      '> span': {
                        '&:first-child': {
                          display: 'none'
                        }
                      }
                    }
                  }
                }}
              >
                <Step className='stepperTask' key={index} active sx={{ mt: 5 }}>
                  <StepLabel
                    StepIconProps={{
                      ...(step.completed && { style: { color: 'green' } })
                    }}
                    icon={step.index}
                  >
                    <Typography> {step.name}</Typography>
                  </StepLabel>
                  <StepContent sx={{ height: '220px', position: 'relative', paddingBottom: '60px' }}>
                    <Tooltip key={step.index} title={step.description}>
                      <Typography variant='body2'>
                        {step.description.length <= 150 ? step.description : `${step.description.slice(0, 150)}...`}
                      </Typography>
                    </Tooltip>
                    {step.due_date && (
                      <Typography variant='body2' sx={{ marginTop: '30px' }}>
                        Due Date:&nbsp;{' '}
                        <Chip
                          variant='outlined'
                          size='small'
                          label={`${step.due_date ? moment(step.due_date).format('DD/MM/YYYY') : ''}`}
                        />
                      </Typography>
                    )}
                    <Typography variant='body2'>
                      Due Time: &nbsp;
                      <Chip size='small' label={`${step?.due_time?.hours}:${step?.due_time?.minutes}`} variant='outlined' />
                    </Typography>
                    {step.repetition.length > 0 && (
                      <Grid container gap={1} mt={2}>
                        <RenderChips activeDay={step?.repetition} />
                      </Grid>
                    )}
                    <Button
                      color={step.completed ? 'success' : 'primary'}
                      variant='contained'
                      size='small'
                      sx={{ mt: 2, position: 'absolute', bottom: '0px' }}
                      onClick={() => {
                        setIsCompletedTask(step.completed)
                        setTaskId(step._id)
                      }}
                    >
                      {step.completed ? 'View' : 'Complete'}
                    </Button>
                  </StepContent>
                </Step>
              </Grid>
            ))}
          </Stepper>
        </Grid>
      ))}
    </Grid>
  )
}
export default My_Today_Tasks_Timeline
