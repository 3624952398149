import { Box, Typography } from '@mui/material'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { columns } from './utils/columns'
import { useTableData } from './utils/useTableData'
import { useTableOptions } from './utils/useTableOptions'

const Users_Report_Table = () => {
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState([])
  const [params, setParams] = useState({
    limit: 5,
    page: 0,
    name: null,
    date: new Date(),
    type: null
  })

  useEffect(() => {
    axios
      .get(`taskReport/users`, { params })
      .then((res) => {
        if (res.data.success) {
          setTotal(res.data.total)
          const tableData = res?.data?.data?.map((item) => useTableData(item, params.date))
          setUsers(tableData)
        } else {
          setUsers([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong')
        console.log('error', err)
      })
  }, [params])

  const options = useTableOptions(total, params, setParams)

  return (
    <Box sx={{ marginTop: '10px' }}>
      <Typography variant='h6' sx={{ marginBottom: '5px' }}>
        Users
      </Typography>
      <MUIDataTable columns={columns} options={options} data={users} />
    </Box>
  )
}

export default Users_Report_Table
