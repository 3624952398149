import { Box, Typography } from '@mui/material'

export const columns = [
  { name: 'name', label: 'Name', options: { sort: false } },
  { name: 'project', label: 'Project', options: { sort: false } },
  { name: 'department', label: 'Department', options: { sort: false } },
  {
    name: 'assigned_to_completed',
    label: (
      <Box
        sx={{
          display: 'flex',
          '> .centerAligned': { flex: 1, textAlign: 'center' }
        }}
      >
        <Typography className='centerAligned'>Assigned</Typography>
        <Typography>/</Typography>
        <Typography className='centerAligned'>Completed</Typography>
        <Typography>/</Typography>
        <Typography className='centerAligned'>Not Completed</Typography>
      </Box>
    ),
    options: { sort: false, setCellProps: () => ({ style: { width: '400px' } }) }
  },
  { name: 'progress', label: 'Progress', options: { sort: false } },
  { name: 'more', label: 'More', options: { sort: false } }
]
