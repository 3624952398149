import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const useTableData = (item, date) => {
  const totalTasks = item?.taskData?.assigned_users
  const tasksCompleted = item?.completed
  const tasksNotCompleted = item?.uncompleted
  const percentage = (tasksCompleted?.length / totalTasks.length) * 100
  const percentageCompleted = percentage.toFixed(percentage % 1 === 0 ? 0 : 2) + '%'

  return [
    item?.taskData?.name,
    item?.taskData?.description,
    `${item?.taskData?.due_time?.hours}:${item?.taskData?.due_time?.minutes}`,
    <Box sx={{ display: 'flex', '> .centerAligned': { flex: 1, textAlign: 'center' } }}>
      <Tooltip
        title={totalTasks?.map((user) => (
          <div key={user._id}>{`${user.first_name} ${user.last_name}`}</div>
        ))}
      >
        <Typography className='centerAligned'>{totalTasks?.length}</Typography>
      </Tooltip>
      <Typography>/ </Typography>
      <Tooltip
        title={
          tasksCompleted?.length > 0
            ? tasksCompleted?.map((user) => <div key={user._id}>{`${user.first_name} ${user.last_name}`}</div>)
            : 'No one completed this task'
        }
      >
        <Typography className='centerAligned'>{tasksCompleted?.length}</Typography>
      </Tooltip>
      <Typography>/</Typography>
      <Tooltip
        title={
          tasksNotCompleted?.length > 0
            ? tasksNotCompleted?.map((user) => <div key={user._id}>{`${user.first_name} ${user.last_name}`}</div>)
            : 'All users completed this task'
        }
      >
        <Typography className='centerAligned'>{tasksNotCompleted.length}</Typography>
      </Tooltip>
    </Box>,
    <Box
      sx={{
        background: `linear-gradient(90deg, rgba(0,255,29,1) 0%, rgba(255,255,255,1) ${percentageCompleted})`,
        paddingX: '5px',
        width: '90%'
      }}
    >
      {percentageCompleted}
    </Box>,
    <Link to={`/tasks-reports/task/${item?.taskData?._id}?date=${date}`}>
      <Button size='small' variant='contained'>
        View
      </Button>
    </Link>
  ]
}
