import Blacklist from './components/Blacklist/Blacklist'
import Blacklists from './components/Blacklist/Blacklists'
import BusinessTrip from './components/BusinessTrip'
import Dashboard from './components/Dashboard/Dashboard'
import Leaves from './components/Leaves/Leaves'
import ManageLeaves from './components/ManageLeaves/ManageLeaves'
import ManageWages from './components/ManageWages/ManageWages'
import ProcessWages from './components/ManageWages/ProcessWages'
import Panel from './components/Panel/Panel'
import Profile from './components/Profile/Profile'
import ResignsDetails from './components/Resigns/ResignDetails'
import Resigns from './components/Resigns/Resigns'
import EditOrder from './components/SalesOrders/EditOrder'
import SalesOrders from './components/SalesOrders/SalesOrders'
import SalesUserOrders from './components/SalesOrders/SalesUserOrders'
import Activity from './components/Settings/Activity/Activity'
import EditActivity from './components/Settings/Activity/EditActivity'
import Banks from './components/Settings/Banks/Banks'
import EditBanks from './components/Settings/Banks/EditBanks'
import Category from './components/Settings/Category/Category'
import EditCategory from './components/Settings/Category/EditCategory'
import Cities from './components/Settings/Cities/Cities'
import EditCities from './components/Settings/Cities/EditCities'
import Departments from './components/Settings/Departments/Departments'
import EditDepartments from './components/Settings/Departments/EditDepartments'
import ExitList from './components/Settings/ExitList/ExitList'
import EditHolidays from './components/Settings/Holidays/EditHolidays'
import Holidays from './components/Settings/Holidays/Holidays'
import EditLanguage from './components/Settings/Language/EditLanguage'
import Language from './components/Settings/Language/Language'
import EditProjects from './components/Settings/Projects/EditProjects'
import Projects from './components/Settings/Projects/Projects'
import EditRoles from './components/Settings/Roles/EditRoles'
import Roles from './components/Settings/Roles/Roles'
import EditSalaryCalculationMethod from './components/Settings/SalaryCalculations/EditSalaryCalculation'
import SalaryCalculations from './components/Settings/SalaryCalculations/SalaryCalculations'
import Warnings from './components/Settings/Warnings/Warnings'
import EditWorkingStatus from './components/Settings/WorkingStatus/EditWorkingStatus'
import WorkingStatus from './components/Settings/WorkingStatus/WorkingStatus'
import EditUsers from './components/Users/EditUsers'
import Wages from './components/Wages/Wages'
import Applicant from './modules/Applicants/applicantRequests'
import Applicants from './modules/Applicants/applicantsRequests'
import Compliance_Records from './modules/Compliance/pages/compliance'
import Badges from './modules/NewEntry/pages/badges'
import Edit_Mozo_Orders from './modules/Orders/Mobilezone/pages/editMozoOrders'
import Mozo_Orders from './modules/Orders/Mobilezone/pages/mozoOrders'
import My_Mozo_Orders from './modules/Orders/Mobilezone/pages/myMozoOrders'
import Staffing_Requests from './modules/StaffingRequests/pages/Staffing_Requests'
import Task_Reports_Details from './modules/Tasks/pages/Details/Task'
import User_Reports_Details from './modules/Tasks/pages/Details/User'
import Manage_Tasks from './modules/Tasks/pages/Manage_Tasks'
import My_Tasks from './modules/Tasks/pages/My_Tasks'
import Task_Details from './modules/Tasks/pages/Task_Details'
import Users from './modules/Users/pages/Users'

export const getRoutes = ({
  user_level,
  isTaskAdmin,
  isTaskUser,
  isITUser,
  isComplianceUser,
  isQualityUser,
  isSalesUser,
  isMobileZoneDepartment
}) => [
    {
      name: 'Ballina',
      path: '/dashboard',
      component: Dashboard,
      icon: 'icon-grid',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      name: 'Paneli',
      path: '/panel',
      component: Panel,
      icon: 'icon-speedometer',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      name: 'Profili',
      path: '/profile',
      component: Profile,
      icon: 'icon-user',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      path: '/leaves/:id',
      component: Leaves,
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      name: 'Pushimet e mia',
      path: '/leaves',
      component: Leaves,
      icon: 'icon-home',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      path: '/manageLeaves/:userId/:leaveId',
      component: ManageLeaves,
      allowed_user_levels: [1, 2, 3, 5, 6, 8]
    },
    {
      path: '/manageLeaves/:userId',
      component: ManageLeaves,
      allowed_user_levels: [1, 2, 3, 5, 6, 8]
    },
    {
      name: 'Menaxho Pushimet',
      path: '/manageLeaves',
      component: ManageLeaves,
      icon: 'icon-calendar',
      allowed_user_levels: [1, 2, 3, 5, 6, 8]
    },
    {
      path: '/wages/:userId',
      component: Wages,
      allowed_user_levels: [1, 2, 5, 6]
    },
    {
      name: 'Pagat e mia',
      path: '/wages',
      component: Wages,
      icon: 'far fa-money-bill-alt',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      name: 'Menaxho Pagat',
      path: '/manageWages',
      component: ManageWages,
      icon: 'far fa-credit-card',
      allowed_user_levels: [1, 2, 5]
    },
    {
      path: '/processWages/:wageImportID',
      component: ProcessWages,
      allowed_user_levels: [1, 2, 5]
    },
    {
      name: 'Përdoruesit',
      path: '/users',
      component: Users,
      icon: 'icon-people',
      allowed_user_levels: [1, 2, 3, 5, 6]
    },
    {
      name: 'Badges',
      path: '/new-entries',
      component: Badges,
      icon: 'far fa-id-badge',
      allowed_user_levels: [1],
      allowed_condition: isITUser
    },
    {
      name: 'Kërkesat për personel',
      path: '/staffing-requests',
      component: Staffing_Requests,
      icon: 'icon-user-follow',
      allowed_user_levels: [1, 2, 5, 6]
    },
    {
      path: '/applicants/:applicantId',
      component: Applicant,
      allowed_user_levels: [1, 5, 6]
    },
    {
      name: 'Aplikantët',
      path: '/applicants',
      component: Applicants,
      icon: 'icon-people',
      allowed_user_levels: [1, 5, 6]
    },
    {
      name: 'Lista e dorëheqjeve',
      path: '/resigns',
      component: Resigns,
      icon: 'icon-note',
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/resignsDetails/:resignId',
      component: ResignsDetails,
      allowed_user_levels: [1, 5, 6]
    },
    {
      name: 'Lista e daljes',
      path: '/exitList',
      component: ExitList,
      icon: 'icon-user-unfollow',
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/blacklists/:blacklistId',
      component: Blacklist,
      allowed_user_levels: [1, 5, 6]
    },
    {
      name: 'Lista e zezë',
      path: '/blacklists',
      component: Blacklists,
      icon: 'far fa-times-circle',
      allowed_user_levels: [1, 5, 6]
    },
    {
      name: 'Taskat e mia',
      path: '/my-tasks',
      component: My_Tasks,
      allowed_user_levels: [1],
      icon: 'icon-list',
      allowed_condition: isTaskUser
    },
    {
      path: '/manage-tasks/:id',
      component: Task_Details,
      allowed_user_levels: [1, 2],
      allowed_condition: isTaskAdmin
    },
    {
      name: 'Menaxho Taskat',
      path: '/manage-tasks',
      component: Manage_Tasks,
      allowed_user_levels: [1, 2],
      icon: 'far fa-list-alt',
      allowed_condition: isTaskAdmin
    },
    {
      path: '/tasks-reports/task/:id',
      component: Task_Reports_Details,
      allowed_user_levels: [1, 2],
      allowed_condition: isTaskAdmin
    },
    {
      path: '/tasks-reports/user/:id',
      component: User_Reports_Details,
      allowed_user_levels: [1, 2],
      allowed_condition: isTaskAdmin
    },
    {
      name: 'Udhëtim Biznesi',
      path: '/businesstrip',
      component: BusinessTrip,
      icon: 'icon-plane',
      allowed_user_levels: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      name: 'Compliance',
      path: '/compliance',
      component: Compliance_Records,
      icon: 'fas fa-shield-alt',
      allowed_user_levels: [1],
      allowed_condition: isComplianceUser
    },
    {
      name: 'Sales Orders',
      path: '/salesorders',
      component: SalesOrders,
      icon: 'fas fa-cart-arrow-down',
      allowed_user_levels: [1],
      allowed_condition: ([2, 3].includes(user_level) && isSalesUser) || isQualityUser
    },
    {
      name: 'My Orders',
      path: '/myorders',
      component: SalesUserOrders,
      icon: 'fas fa-cart-arrow-down',
      allowed_user_levels: [1],
      allowed_condition: user_level == 4 && (isSalesUser || isQualityUser)
    },
    {
      name: 'MoZo Orders',
      path: '/mozoorders',
      component: Mozo_Orders,
      icon: 'fas fa-cart-arrow-down',
      allowed_user_levels: [1],
      allowed_condition: isMobileZoneDepartment && [2, 3, 4].includes(user_level)
    },
    {
      name: 'My MoZo Orders',
      path: '/mymozoorders',
      component: My_Mozo_Orders,
      icon: 'fas fa-cart-arrow-down',
      allowed_user_levels: [1],
      allowed_condition: isMobileZoneDepartment && [2, 3, 4].includes(user_level)
    },
    {
      path: '/departments',
      component: Departments,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editDepartments/:idDepartment',
      component: EditDepartments,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editDepartments',
      component: EditDepartments,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/language',
      component: Language,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editLanguage/:idLanguage',
      component: EditLanguage,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editLanguage',
      component: EditLanguage,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/projects',
      component: Projects,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editProjects/:idProjects',
      component: EditProjects,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editProjects',
      component: EditProjects,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/category',
      component: Category,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/editCategory/:idCategory',
      component: EditCategory,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/editCategory',
      component: EditCategory,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/holidays',
      component: Holidays,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/editHolidays/:idHoliday',
      component: EditHolidays,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/editHolidays',
      component: EditHolidays,
      allowed_user_levels: [1, 5, 6, 8]
    },
    {
      path: '/roles',
      component: Roles,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editRoles/:idRoles',
      component: EditRoles,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editRoles',
      component: EditRoles,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/cities',
      component: Cities,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editCities/:idCity',
      component: EditCities,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editCities',
      component: EditCities,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/warnings',
      component: Warnings,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/banks',
      component: Banks,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editBanks/:idBank',
      component: EditBanks,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editBanks',
      component: EditBanks,
      allowed_user_levels: [1, 5, 6]
    },

    {
      path: '/editUsers/:idUser',
      component: EditUsers,
      allowed_user_levels: [1, 2, 3, 5, 6]
    },
    {
      path: '/editUsers',
      component: EditUsers,
      allowed_user_levels: [1, 5, 6]
    },

    {
      path: '/activity',
      component: Activity,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editActivity/:idActivity',
      component: EditActivity,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editActivity',
      component: EditActivity,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/workingStatus',
      component: WorkingStatus,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editWorkingStatus/:idWorkingStatus',
      component: EditWorkingStatus,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editWorkingStatus',
      component: EditWorkingStatus,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/salaryMethods',
      component: SalaryCalculations,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editSalaryMethod/:methodId',
      component: EditSalaryCalculationMethod,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editSalaryMethod',
      component: EditSalaryCalculationMethod,
      allowed_user_levels: [1, 5, 6]
    },
    {
      path: '/editOrder/:orderId',
      component: EditOrder,
      allowed_user_levels: [1],
      allowed_condition: [2, 3, 4].includes(user_level) && (isSalesUser || isQualityUser)
    },
    {
      path: '/editMozoOrder/:orderId',
      component: Edit_Mozo_Orders,
      allowed_user_levels: [1],
      allowed_condition: isMobileZoneDepartment && [2, 3, 4].includes(user_level)
    },

    {
      name: 'Konfigurimet',
      icon: 'icon-settings',
      allowed_user_levels: [1, 5, 6, 7, 8],
      submenu: [
        {
          name: 'Projektet',
          path: '/projects',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Departamentet',
          path: '/departments',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Gjuhët',
          path: '/language',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Vërejtjet',
          path: '/warnings',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Kategoritë e pushimeve',
          path: '/category',
          allowed_user_levels: [1, 5, 6, 7, 8]
        },
        {
          name: 'Pushimet Shtetërore',
          path: '/holidays',
          allowed_user_levels: [1, 5, 6, 7, 8]
        },
        {
          name: 'Pozitat',
          path: '/roles',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Qytetet',
          path: '/cities',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Bankat',
          path: '/banks',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Aktivitetet',
          path: '/activity',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: 'Statuset e punës',
          path: '/workingStatus',
          allowed_user_levels: [1, 5, 6, 7]
        },
        {
          name: "Metodat e pagave",
          path: '/salaryMethods',
          allowed_user_levels: [1, 5, 6, 7]
        }
      ]
    }
  ]
