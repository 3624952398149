import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { checkedIcon, office_location, unCheckedIcon } from '../../utils'
import MUIDataTable from 'mui-datatables'

const New_Tasks_User_Profile_Modal = ({ handleAddingNewTaskProfiles, users }) => {
  const [params, setParams] = useState({ limit: 5, page: 0 })
  const [total, setTotal] = useState(null)
  const [departments, setDepartments] = useState([])
  const [nonTaskProfiles, setNonTaskProfiles] = useState([])
  const [addAsTaskProfiles, setAddAsTaskProfiles] = useState([])

  useEffect(() => {
    axios
      .get(`taskUsers`, { params: { ...params, ...(users && { noManagers: true }) } })
      .then((res) => {
        if (res.data.success) {
          const tableData = res.data.data.map((item) => {
            return [
              item?._id,
              item?.first_name,
              item?.last_name,
              item?.department?.map((item) => item?.name).join(', '),
              item?.office_location,
              <Checkbox size='small' />
            ]
          })
          setNonTaskProfiles(tableData)
          setTotal(res.data.total)
        } else {
          setNonTaskProfiles([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [params])

  useEffect(() => {
    axios
      .get(`departments`)
      .then((res) => {
        if (res.data.success) {
          setDepartments(res.data.data)
        } else {
          setDepartments([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [])

  const addUsersAsTaskAdmins = () => {
    axios
      .post('taskUsers/admins', { users: addAsTaskProfiles })
      .then((res) => {
        if (res.data.success) {
          toast.success('You successfully added those users as task admins')
        } else {
          toast.error(res.data.message)
        }
        handleAddingNewTaskProfiles()
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }
  const addUsersAsTaskUsers = () => {
    axios
      .post('taskUsers/users', { users: addAsTaskProfiles })
      .then((res) => {
        if (res.data.success) {
          toast.success('You successfully added those users as task users')
        } else {
          toast.error(res.data.message)
        }
        handleAddingNewTaskProfiles()
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }

  const handleRowClick = (rowData) => {
    const selectedUserId = rowData[0]
    setAddAsTaskProfiles((prev) => {
      if (prev.includes(selectedUserId)) {
        return prev.filter((userId) => userId !== selectedUserId)
      } else {
        return [...prev, selectedUserId]
      }
    })
  }

  const options = {
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    count: total,
    rowsPerPage: 5,
    elevation: 0,
    responsive: 'scroll',
    maxHeight: 'none',
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onRowClick: (rowData) => {
      handleRowClick(rowData)
    },
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setParams((prev) => ({ ...prev, page: e }))
    }
  }

  const columns = [
    { name: '_id', label: '_id', options: { sort: false, display: false } },
    { name: 'first_name', label: 'First Name', options: { sort: false } },
    { name: 'last_name', label: 'Last Name', options: { sort: false } },
    { name: 'department', label: 'Department', options: { sort: false } },
    { name: 'location', label: 'Location', options: { sort: false } },
    {
      name: 'add_task_admin',
      label: 'Add as Task Admin',
      options: {
        sort: false,
        customBodyRender: (rowData, rowMeta) => (
          <Checkbox size='small' checked={addAsTaskProfiles.includes(rowMeta?.rowData?.[0]) ?? false} />
        )
      }
    }
  ]

  return (
    <Dialog open maxWidth='lg' fullWidth sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>Add new Tasks {users ? 'Users' : 'Admins'}</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} sx={{ paddingY: '10px' }}>
          <Grid item xs={4}>
            <TextField
              label='Search User'
              size='small'
              fullWidth
              sx={{ marginRight: '10px' }}
              onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
            />
          </Grid>
          {departments && (
            <Grid item xs={4}>
              <Autocomplete
                multiple
                sx={{ maxWidth: '100%' }}
                disableCloseOnSelect
                options={departments}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                onChange={(_e, selectedOptions) => {
                  const selectedIds = selectedOptions.map((option) => option._id)
                  setParams((prev) => ({ ...prev, departments: selectedIds }))
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label='Departments' placeholder='Departments' size='small' />}
              />
            </Grid>
          )}
          {office_location && (
            <Grid item xs={4}>
              <Autocomplete
                multiple
                sx={{ maxWidth: '100%' }}
                disableCloseOnSelect
                options={office_location}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_e, selectedOptions) => {
                  const selectedIds = selectedOptions.map((option) => option.value)
                  setParams((prev) => ({ ...prev, locations: selectedIds }))
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label='Location' placeholder='Location' size='small' />}
              />
            </Grid>
          )}
        </Grid>

        <MUIDataTable columns={columns} options={options} data={nonTaskProfiles} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAddingNewTaskProfiles()} size='small' variant='contained' color='error'>
          Cancel
        </Button>
        <Button
          onClick={() =>
            addAsTaskProfiles.length ? (users ? addUsersAsTaskUsers() : addUsersAsTaskAdmins()) : handleAddingNewTaskProfiles()
          }
          size='small'
          variant='contained'
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default New_Tasks_User_Profile_Modal
