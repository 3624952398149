import { Box, Button, Typography } from '@mui/material'
import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import New_Task_Modal from './../../../New_Task_Modal/New_Task_Modal'
import Table from './components/Table/Table'

const Manage_Task_Table = () => {
  const token = localStorage.getItem('token')
  const decodedToken = token ? jwt_decode(token) : null
  const isOnlyManager = decodedToken?.user_level === 2 && !decodedToken?.isTaskAdmin
  const [addNewModal, setAddNewModal] = useState(false)

  return (
    <>
      <Box
        sx={{
          '.MuiToolbar-gutters': { padding: 0 }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '25px' }}>
          <Typography variant='h5'>Manage Active Tasks</Typography>
          <Button variant='contained' onClick={() => setAddNewModal(true)}>
            Add new Task
          </Button>
          {addNewModal && (
            <New_Task_Modal
              isOnlyManager={isOnlyManager}
              department={decodedToken?.department[0]}
              onCloseModal={() => {
                setAddNewModal(false)
              }}
            />
          )}
        </Box>

        <Table addNewModal={addNewModal} is_active={true} />
      </Box>
      <Box
        sx={{
          '.MuiToolbar-gutters': { padding: 0 }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '25px', marginTop: '25px' }}>
          <Typography variant='h5'>Manage InActive Tasks</Typography>
        </Box>
        <Table task_type={['repetitive']} />
      </Box>
    </>
  )
}

export default Manage_Task_Table
