import { Box, Typography } from '@mui/material'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { columns } from './utils/columns'
import { useTableOptions } from './utils/useTableOptions'
import { useTableData } from './utils/useTableData'

const Tasks_Report_Table = () => {
  const [tasks, setTasks] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    limit: 5,
    page: 0,
    name: null,
    date: new Date(),
    type: null
  })

  useEffect(() => {
    axios
      .get(`taskReport/tasks`, { params })
      .then((res) => {
        if (res.data.success) {
          setTotal(res.data.total)
          const tableData = res?.data?.data?.map((item) => useTableData(item, params.date))
          setTasks(tableData)
        } else {
          setTasks([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [params])

  const options = useTableOptions(total, params, setParams)

  return (
    <Box>
      <Typography variant='h6' sx={{ marginBottom: '5px' }}>
        Tasks
      </Typography>
      <MUIDataTable columns={columns} options={options} data={tasks} />
    </Box>
  )
}

export default Tasks_Report_Table
